<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Nombre" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
      <!-- <el-col :lg="2" :xs="8" :offset="0">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExport"
          size="mini"
          type="success"
          icon="el-icon-bottom"
        >Exportar</el-button>
      </el-col> -->
    </el-row>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="12">
        <el-select v-model="searchForm.location" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
          <el-option
            v-for="statusItem in status"
            :key="statusItem.status"
            :label="statusItem.status"
            :value="statusItem.status">
          </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="16" :md="16" :sm="10" :xs="24">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <el-upload
        :show-file-list="false"
        :http-request="handleUploadFile"
        :before-upload="beforeUploadFile"
        action="#"
        class="upload-demo">
          <el-button
            size="mini"
            type="success"
            icon="el-icon-upload2"
          >Subir archivo</el-button>
      </el-upload>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
     <el-table-column label="ID" width="130" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{scope.row._id}}</div>
            <span>{{ scope.row._id.toString().substr(-4) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" width="170" align="center">
        <template slot-scope="scope">
          <span><a :href="`${host + scope.row.file}`">{{ scope.row.name }}</a></span>
        </template>
      </el-table-column>
      <el-table-column label="Clave" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.key }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="110" align="center">
        <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 'loaded'" size="small"> {{ scope.row.status }} </el-tag>
            <el-tag v-else-if="scope.row.status == 'processed'" type="success" size="small"> {{ scope.row.status }} </el-tag>
            <el-tag v-else size="small"> {{ scope.row.status }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Total" width="120" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Total registros" width="150" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.countRecords }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Total errores" width="110" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.countErrors }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de procesamiento" width="170" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.processingDate | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de carga" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" width="250" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 'processed'" @click="handleShowInfo(scope.row.key)" type="primary" size="mini">
            <span>Registros</span>
          </el-button>
          <el-button v-else-if="scope.row.status == 'loaded'" @click="handleProcess(scope.row.key)" type="warning" size="mini">
            <span>Procesar</span>
          </el-button>
          <el-button v-if="scope.row.status == 'processed' && scope.row.countRecords != scope.row.countErrors" @click="handleSearchPayments(scope.row._id)" type="primary" size="mini">
            <span>Pagos</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, uploadFile, filterData } from '@/api/prosa'

const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi.slice(0, conf.baseApi.indexOf('api-manager'))

export default {
  data () {
    return {
      host: urlServer,
      searchForm: {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      },
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      status: null,
      types: null
    }
  },
  mounted () {
    this.handleGetStatus()
    this.handleSearchFilter()
  },
  methods: {
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        location: '',
        type: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    handleShowInfo (key) {
      this.$router.push({ name: 'prosa-info', query: { key: key } })
    },
    handleProcess (key) {
      this.$router.push({ name: 'prosa-process', query: { key: key } })
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          console.log(response)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    beforeUploadFile (file) {
      // const isEXCEL2 = file.type === 'application/vnd.ms-excel'
      // const isCSV = file.type === 'text/csv'
      const isEXCEL = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const isLt10M = file.size / 1024 / 1024 < 10
      var flag = true

      if (!isEXCEL) {
        this.$message.error('¡El archivo debe estar en un formato valido!')
        flag = false
      }
      if (!isLt10M) {
        this.$message.error('¡El archivo excede los 10MB!')
      }
      return flag && isLt10M
    },
    async handleUploadFile (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      uploadFile(formData)
        .then((response) => {
          this.loading = false
          console.log(response)
          param.onSuccess(response)
          this.$message({
            showClose: true,
            message: response.messages[0],
            type: 'success'
          })
          this.$router.push({ name: 'prosa-process', query: { key: response.data.key } })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleGetStatus () {
      this.loading = true
      await filterData({ fieldSearch: 'status' })
        .then((response) => {
          console.log(response)
          this.status = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchPayments (id) {
      this.$router.push({ name: 'payments-store', query: { idGroupTransaction: id } })
    }
  }
}
</script>
